import { Injectable } from '@angular/core';
import { HarvestDeliveryNote } from './../models/harvest-delivery-note.model';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import User from '@core/models/user.model';
import { DefaultPackRate } from '../models/defaultPackRate.model';

@Injectable({
  providedIn: 'root',
})
export class HarvestDeliveryNoteService extends ApiService<HarvestDeliveryNote> {
  protected endpoint = 'harvest-delivery-note';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getDetails(id: string, options?: ListOptions): Observable<User[]> {
    let url = `/${this.endpoint}/${id}/details`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<User[]>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public getProduction(
    data: { block_id: string },
    options?: ListOptions,
  ): Observable<{
    product_name: string;
    production_id: string;
  }> {
    let url = `/${this.endpoint}/production`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post<ApiSingleResult<{ product_name: string; production_id: string }>>(url, data).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }

  public getProductionByBlockAndDate(
    data: { block_id: string; date: string },
    options?: ListOptions,
  ): Observable<{
    product_name: string;
    production_id: string;
    product_id: string;
  }> {
    let url = `/${this.endpoint}/production`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp
      .post<ApiSingleResult<{ product_name: string; production_id: string; product_id: string }>>(url, data)
      .pipe(
        map((result) => {
          return result?.data;
        }),
      );
  }

  public getDefaultPackRate(productionId: string, options?: ListOptions): Observable<DefaultPackRate> {
    let url = `/${this.endpoint}/${productionId}/default-pack-rate`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<DefaultPackRate>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }
}
